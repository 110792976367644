import { CenterWidthContainer, StorefrontPageContainer } from "components/LEGACY/styled-components/Storefront"
import SiteFooter from "components/header-footer/SideFooter"
import BundleModalLoader from "components/orderPage/BundleModal"
import CategoryDisplay from "components/orderPage/CategoryDisplay"
import CategorySelectors from "components/orderPage/CategorySelectors"
import CategoryBreadcrumb from "components/orderPage/CategorySelectors/CategoryBreadcrumb"
import FeaturedProducts from "components/orderPage/FeaturedProducts"
import NoticeIfEmptyShop from "components/orderPage/NoticeIfEmptyShop"
import ProductGroupModalLoader from "components/orderPage/ProductGroupModal"
import BasketLoader from "components/orderPage/StickyBasket/BasketLoader"
import StickyHeader from "components/orderPage/StickyHeader"
import TaxNote from "components/orderPage/TaxNote"
import TokenAuthenticator from "components/orderPage/TokenAuthenticator"
import URLBasketIdLoader from "components/orderPage/URLBasketIdLoader"
import { BasketOpenContextProvider } from "context/orderFormContexts/BasketOpenContext"
import { OrderLoadingModalProvider } from "context/orderFormContexts/OrderLoadingModalContext"
import React from "react"
import OrderLoadingModal from "./OrderLoadingModal"
import ShopHeader from "./ShopHeader"

interface MainViewProps {
  backgroundImage?: string | null
  useCustomBackground?: boolean
  backgroundOverlay?: {
    color: string
    opacity: number
  }
  mobileBackgroundImage?: string | null
  backgroundColor?: string
}

const MainView = ({
  backgroundImage,
  useCustomBackground,
  backgroundOverlay,
  mobileBackgroundImage,
  backgroundColor,
}: MainViewProps) => {
  return (
    <>
      <style jsx>{`
        main {
          position: relative;
        }
      `}</style>
      <BasketOpenContextProvider>
        <OrderLoadingModalProvider>
          <StorefrontPageContainer
            backgroundImage={backgroundImage}
            useCustomBackground={useCustomBackground}
            backgroundOverlay={backgroundOverlay}
            mobileBackgroundImage={mobileBackgroundImage}
            backgroundColor={backgroundColor}
          >
            <CenterWidthContainer>
              <ShopHeader />
              <StickyHeader />
              <main>
                <TaxNote />
                <NoticeIfEmptyShop />
                <FeaturedProducts />
                <CategoryBreadcrumb />
                <CategorySelectors />
                <CategoryDisplay />
              </main>
            </CenterWidthContainer>
            <SiteFooter />

            <ProductGroupModalLoader />
            <BundleModalLoader />
            <BasketLoader />
            <TokenAuthenticator />
            <URLBasketIdLoader />
            <OrderLoadingModal />
          </StorefrontPageContainer>
        </OrderLoadingModalProvider>
      </BasketOpenContextProvider>
    </>
  )
}

export default React.memo(MainView)
